import "@hotwired/turbo-rails"
import { Dropdown, Collapse, Tab } from 'bootstrap'

Array.from(document.querySelectorAll('.dropdown-toggle')).map(n => new Dropdown(n))
Array.from(document.querySelectorAll('.collapse')).map(n => new Collapse(n, { toggle: false }))
Array.from(document.querySelectorAll('[data-toggle=pill]')).map(n => {
    const trigger = new Tab(n)
    n.addEventListener('click', e => {
        e.preventDefault()
        trigger.show()
    })
})